import { gql } from "@apollo/client";
import { imageType } from "../constants";

export const SEARCH_CATALOG = gql`
  query Search($from: Int, $size: Int, $input: SearchInput, $externalId: ID) {
    search(from: $from, size: $size, input: $input, externalId: $externalId) {
      hits {
        total
        results {
          id
          displayName
          organizationName
          organizationId
          organizationPlan
          images {
            ${imageType}
          }
          categories {
            id
            displayName
            parentCategoryId
          }
          brand {
            id
            displayName
          }
          files {
            id
            values {
              ${imageType}
            }
          }
          properties {
            id
            value
            values
          }
          sustainabilityRanks {
            environmental
            economic
            social
          }
        }
      }
    }
  }
`;